import React from "react"

export const DataprivacySection = () => (
  <section id="dataprivacy" className="pb-section pb-impressum-section">
    <div className="container content">
      <h1 className="title pb-section-title">Datenschutzerklärung</h1>
      <p className="paragraph">
        Willkommen auf der Website unserer Arztpraxis. In dieser
        Datenschutzerklärung erläutern wir, wie wir personenbezogene Daten auf
        unserer Website (A.) und im Rahmen der Patientenbehandlung in unserer
        Arztpraxis (B.) erheben und bearbeiten und wir erläutern Ihre Rechte
        (C). Unter Personendaten werden alle Angaben verstanden, die sich auf
        eine bestimmte oder bestimmbare Person beziehen.
      </p>
      <p className="paragraph">
        Die Erhebung und die Verarbeitung Ihrer personenbezogenen Daten
        geschehen unter Beachtung der geltenden datenschutzrechtlichen Gesetze
        und Anforderungen. Im Übrigen lehnt die Arztpraxis jegliche Haftung bei
        der Nutzung dieser Website ab.
      </p>
      <p className="paragraph">
        Grundsätzlich bietet unsere Arztpraxis ihre Dienstleistungen nur in der
        Schweiz an und richtet sich nur an in der Schweiz wohnhafte Personen.
      </p>
      <h2 className="subtitle">Verantwortlich für die Datenbearbeitung ist:</h2>
      <p className="paragraph">
        Praxis Burgweg, Sven Streit, Burgweg 1, 3510 Konolfingen
      </p>
      <p className="paragraph">
        Wenn Sie Fragen über unseren Umgang mit Personendaten oder sonstige
        datenschutzrechtliche Anliegen haben, können Sie uns unter der
        vorgenannten Adresse, per E-Mail praxisburgweg@hin.ch oder Telefon 031
        971 16 46 kontaktieren.
      </p>
      <h2 className="title is-4 mt-6">A. Nutzung Website</h2>
      <h2 className="subtitle">Erhebung und Zweck der Bearbeitung</h2>
      <p className="paragraph">
        Mit jedem Aufruf der Website werden eine Reihe von allgemeinen Daten und
        Informationen erfasst. Diese allgemeinen Daten und Informationen werden
        in den Logfiles des Servers gespeichert. Erfasst werden können (1) die
        verwendeten Browsertypen und Versionen, (2) das vom zugreifenden System
        verwendete Betriebssystem, (3) die Website, von welcher ein zugreifendes
        System auf unsere Website gelangt (sogenannte Referrer), (4) die
        Unterwebsites, welche über ein zugreifendes System auf unserer Website
        angesteuert werden, (5) das Datum und die Uhrzeit eines Zugriffs auf die
        Website, (6) eine Internet-Protokoll-Adresse (IP-Adresse), (7) der
        Internet-Service-Provider des zugreifenden Systems und (8) sonstige
        ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle von
        Angriffen auf unsere IT-Infrastruktur dienen.
      </p>
      <p className="paragraph">
        Diese Daten und Informationen werden benötigt, um (1) die Inhalte
        unserer Website korrekt darzustellen, (2) die Inhalte unserer Website zu
        optimieren, (3) die dauerhafte Funktionsfähigkeit unserer
        IT-Infrastruktur und der Technik unserer Website zu gewährleisten sowie
        um (4) im Falle eines Cyberangriffes den Strafverfolgungsbehörden die
        notwendigen Informationen bereitzustellen.
      </p>
      <p className="paragraph">
        Weiter kann unsere Arztpraxis diese Daten und Informationen einerseits
        statistisch und ferner mit dem Ziel auswerten, den Datenschutz und die
        Datensicherheit ihrer IT-Infrastruktur zu erhöhen. Die erhobenen Daten
        und Informationen werden nur zu den eben erwähnten Zwecken bearbeitet
        und werden anschliessend gelöscht.
      </p>
      <h2 className="subtitle">Links anderer Websites </h2>
      <p className="paragraph">
        Diese Website kann Links zu Websites anderer Anbieter enthalten. Unsere
        Arztpraxis hat keinen Einfluss auf diese Websites, deren Inhalte,
        Angebote oder Verfügbarkeit oder die dort geltenden
        Datenschutzerklärungen und -bestimmungen. Sie lehnt daher jegliche
        Haftung ab.
      </p>
      <h2 className="subtitle">Cookies und weitere Serviceangebote</h2>
      <p className="paragraph">
        Unsere Arztpraxis kann auf ihrer Website Cookies verwenden. Cookies
        enthalten eine sogenannte Cookie-ID, eine eindeutige Kennung des
        Cookies, welche Websites und Server einem konkreten Internetbrowser
        zuordnet. Ein bestimmter Internetbrowser kann über diese eindeutige
        Cookie-ID wiedererkannt und identifiziert werden. Sie können Ihren
        Browser so einstellen, dass er Cookies zurückweist, nur für eine Sitzung
        speichert oder sonst vorzeitig löscht.
      </p>
      <p className="paragraph">
        Wir setzen auf unserer Website Google Analytics ein. Dies ist ein
        Webanalysedienst von Google Irland (mit Sitz in Irland), welche sich
        dabei auf Google LLC (mit Sitz in den USA) als Auftragsbearbeiter
        stützt. Google Analytics verwendet ebenfalls Cookies. Wir haben den
        Dienst so konfiguriert, dass die IP-Adresse der Besucher von Google in
        Europa vor einer Weiterleitung in die USA gekürzt werden und dadurch
        nicht zurückverfolgt werden können. Ebenso haben wir die Einstellungen
        «Datenweitergabe» und «Signals» ausgeschaltet.
      </p>
      <p className="paragraph">
        Solche Informationen über die Nutzung dieser Website (einschliesslich
        Ihrer IP-Adresse) können z.B. im Falle von Google Analytics an Server im
        Ausland übertragen und dort gespeichert werden. Mit der Nutzung unserer
        Website erklären Sie sich einverstanden mit der Übertragung dieser
        Daten. Befindet sich ein Empfänger in einem Land ohne angemessenen
        gesetzlichen Datenschutz, verpflichten wir den Empfänger vertraglich zur
        Einhaltung des anwendbaren Datenschutzes, soweit er nicht bereits einem
        gesetzlich anerkannten Regelwerk zur Sicherstellung des Datenschutzes
        unterliegt und wir uns nicht auf eine Ausnahmebestimmung stützen können.
      </p>
      <h2 className="subtitle">Kommunikationsmöglichkeiten</h2>
      <p className="paragraph">
        Die Website unserer Arztpraxis enthält gewisse Angaben, die eine
        schnelle elektronische Kontaktaufnahme sowie eine unmittelbare
        Kommunikation ermöglichen, was ebenfalls eine allgemeine E-Mail-Adresse
        umfasst. Nehmen Sie über die auf unserer Website angebotenen
        Kommunikationsmöglichkeiten Kontakt mit uns auf, werden Ihre
        Kontaktangaben und Inhalte lediglich zur Bearbeitung und Beantwortung
        Ihrer Anfrage verwendet.
      </p>
      <p className="paragraph">
        Bitte beachten Sie: Es kann nicht sichergestellt werden, dass Ihre Daten
        vertraulich übermittelt werden. Deshalb sollen bei dieser
        Kontaktaufnahme keine vertraulichen Daten (medizinische Anliegen etc.)
        übermittelt werden.
      </p>
      <h2 className="subtitle">Verwendung von Google Maps</h2>
      <p className="paragraph">
        Diese Website nutzt das Angebot von Google Maps. Dadurch können wir
        Ihnen interaktive Karten direkt in der Website anzeigen und ermöglichen
        Ihnen die komfortable Nutzung der Karten-Funktion. Durch den Besuch auf
        der Website erhält Google die Information, dass Sie die entsprechende
        Unterseite unserer Website aufgerufen haben. Dies erfolgt unabhängig
        davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt
        sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt
        sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die
        Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich
        vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten als
        Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung
        und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche
        Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer)
        zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des
        sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu
        informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung
        dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google
        richten müssen. Weitere Informationen zu Zweck und Umfang der
        Datenerhebung und ihrer Verarbeitung durch Google erhalten Sie neben
        weiteren Informationen zu Ihren diesbezüglichen Rechten und
        Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre unter:{" "}
        <a target="_blank" href="http://www.google.de/intl/de/policies/privacy">
          http://www.google.de/intl/de/policies/privacy
        </a>
        .
      </p>
      <h2 className="title is-4 mt-6">B. Patientenbehandlung</h2>
      <h2 className="subtitle">Erhebung und Zweck der Datenbearbeitung</h2>
      <p className="paragraph">
        Die Bearbeitung (Erhebung, Speicherung, Verwendung sowie Aufbewahrung)
        Ihrer Daten erfolgt aufgrund des Behandlungsvertrages und gesetzlicher
        Vorgaben zur Erfüllung des Behandlungszwecks sowie zu den damit
        verbundenen Pflichten. Die Erhebung von Daten erfolgt einerseits durch
        die/den behandelnde/n Ärztin/Arzt im Rahmen Ihrer Behandlung.
        Andererseits erhalten wir auch Daten von weiteren Ärztinnen/Ärzten und
        Gesundheitsfachpersonen, bei denen Sie in Behandlung waren oder sind,
        falls Sie hierfür Ihre Einwilligung gegeben haben. In Ihrer
        Krankengeschichte werden nur Daten bearbeitet, die im Zusammenhang mit
        Ihrer medizinischen Behandlung stehen. Die Krankengeschichte umfasst die
        auf dem Patientenformular gemachten persönlichen Angaben wie
        Personalien, Kontaktdaten und Versicherungsangaben sowie unter anderem
        das im Rahmen der Behandlung durchgeführte Aufklärungsgespräch, erhobene
        Gesundheitsdaten wie Anamnesen, Diagnosen, Therapievorschläge und
        Befunde.
      </p>
      <h2 className="subtitle">Dauer der Aufbewahrung</h2>
      <p className="paragraph">
        Wir verarbeiten und speichern Ihre Personendaten, solange es für die
        Erfüllung unserer vertraglichen und gesetzlichen Pflichten oder sonst
        für die mit der Bearbeitung verfolgten Zwecke erforderlich ist. Ihre
        Krankengeschichte wird während 20 Jahren nach Ihrer letzten Behandlung
        aufbewahrt. Danach wird sie mit Ihrer ausdrücklichen Einwilligung weiter
        aufbewahrt oder sicher gelöscht bzw. vernichtet.
      </p>
      <h2 className="subtitle">Weitergabe der Daten</h2>
      <p className="paragraph">
        Ihre Personendaten und insbesondere Ihre medizinischen Daten übermitteln
        wir nur dann an externe Dritte, wenn dies gesetzlich erlaubt oder
        verlangt ist oder wenn Sie im Rahmen Ihrer Behandlung in die Weitergabe
        der Daten eingewilligt haben.
      </p>
      <ul>
        <li>
          Die Übermittlung an Ihre Krankenversicherung bzw. an die Unfall- oder
          Invalidenversicherung erfolgt zum Zweck der Abrechnung der Ihnen
          gegenüber erbrachten Leistungen. Die Art der übermittelten Daten
          orientiert sich dabei an den gesetzlichen Vorgaben.
        </li>
        <li>
          Die Weitergabe an kantonale sowie nationale Behörden (z.B.
          kantonsärztlicher Dienst, Gesundheitsdepartemente etc.) erfolgt
          aufgrund gesetzlicher Meldepflichten.
        </li>
      </ul>
      <p className="paragraph">
        Im Einzelfall, abhängig von Ihrer Behandlung und Ihrer entsprechenden
        Einwilligung, erfolgt die Übermittlung von Daten an weitere berechtigte
        Empfänger (z.B. Labore, andere Ärztinnen und Ärzte).
      </p>
      <h2 className="subtitle">Widerruf Ihrer Einwilligung</h2>
      <p className="paragraph">
        Haben Sie für eine Datenbearbeitung Ihre ausdrückliche Einwilligung
        gegeben, können Sie eine bereits erteilte Einwilligung jederzeit ganz
        oder teilweise widerrufen. Der Widerruf oder der Wunsch nach Änderung
        einer Einwilligung hat schriftlich zu erfolgen. Sobald wir Ihren
        schriftlichen Widerruf erhalten haben und die Bearbeitung auf keine
        andere Rechtsgrundlage als die Einwilligung gestützt werden kann, wird
        die Bearbeitung eingestellt. Die Rechtmässigkeit der bis zum Widerruf
        erfolgten Datenbearbeitung bleibt vom Widerruf unberührt.
      </p>
      <h2 className="title is-4 mt-6">C. Allgemeine Hinweise</h2>
      <h2 className="subtitle">Ihre Rechte</h2>
      <p className="paragraph">
        Gestützt auf das Datenschutzgesetz haben Sie verschiedene Rechte. Zur
        Geltendmachung Ihrer Rechte setzen Sie sich bitte mit der Arztpraxis in
        Verbindung. Wir stehen Ihnen für weitere diesbezügliche Informationen
        gerne zur Verfügung.
      </p>
      <ul>
        <li>
          <b></b>
          <h3 className="subtitle">Auskunft, Einsicht und Herausgabe</h3>
          Sie haben jederzeit das Recht, Auskunft zu Ihren Personendaten zu
          erhalten. Sie können Ihre Krankengeschichte einsehen oder auch eine
          Kopie verlangen. Die Herausgabe der Kopie kann kostenpflichtig sein.
          Allfällige Kosten, welche vom Aufwand der Erstellung der Kopie
          abhängen, werden Ihnen vorgängig bekannt gegeben.
        </li>
        <li>
          <h3 className="subtitle">Recht auf Datenübertragung</h3>
          Sie haben das Recht, Daten, die wir automatisiert bzw. digital
          verarbeiten, an sich oder an einen Dritten in einem gängigen,
          maschinenlesbaren Format aushändigen zu lassen. Dies gilt insbesondere
          auch bei der Weitergabe von medizinischen Daten an eine von Ihnen
          gewünschte Gesundheitsfachperson. Sofern Sie die direkte Übertragung
          der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies
          nur, soweit es technisch machbar ist.
        </li>
        <li>
          <h3 className="subtitle">Berichtigung Ihrer Angaben</h3>
          Wenn Sie feststellen oder der Ansicht sind, dass Ihre Daten nicht
          korrekt oder unvollständig sind, haben Sie die Möglichkeit, eine
          Berichtigung zu verlangen. Kann weder die Korrektheit noch die
          Unvollständigkeit Ihrer Daten festgestellt werden, haben Sie die
          Möglichkeit auf die Anbringung eines Bestreitungsvermerks.
        </li>
      </ul>
      <h2 className="subtitle">Änderungen</h2>
      <p className="paragraph">
        Unsere Arztpraxis kann diese Datenschutzerklärung jederzeit ohne
        Vorankündigung anpassen. Es gilt die jeweils aktuelle, auf unserer
        Website publizierte Fassung. Soweit die Datenschutzerklärung Teil eines
        Vertrages mit Ihnen ist, werden wir Sie im Falle einer Aktualisierung
        über die Änderung per E-Mail oder auf andere geeignete Weise
        informieren.
      </p>
      <p>3. Juli 2023</p>
    </div>
  </section>
)
