import React, { useState } from "react"

export const Navbar = () => {
  const [isActive, setIsActive] = useState(false)
  const classSuffix = isActive ? " is-active" : ""
  const setInactive = () => setIsActive(false)
  return (
    <nav
      className="navbar has-shadow is-spaced is-fixed-top"
      role="navigation"
      aria-label="main navigation"
    >
      <a
        role="button"
        className={"navbar-burger burger" + classSuffix}
        aria-label="menu"
        aria-expanded="false"
        data-target="pbNav"
        onClick={() => setIsActive(!isActive)}
      >
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
      <div id="pbNav" className={"navbar-menu" + classSuffix}>
        <div className="navbar-end">
          <a href="/#home" className="navbar-item" onClick={setInactive}>
            Home
          </a>
          <a href="/#praxis" className="navbar-item" onClick={setInactive}>
            Praxis
          </a>
          <a href="/#angebot" className="navbar-item" onClick={setInactive}>
            Angebot
          </a>
          <a href="/#team" className="navbar-item" onClick={setInactive}>
            Team
          </a>
          <a href="/#contact" className="navbar-item" onClick={setInactive}>
            Kontakt
          </a>
        </div>
      </div>
    </nav>
  )
}
