import React from "react"

export const ImpressumSection = () => (
  <section id="impressum" className="pb-section pb-impressum-section">
    <div className="container content">
      <h1 className="title pb-section-title">Impressum</h1>
      <h2 className="subtitle">Kontakt-Adresse</h2>
      <p className="paragraph">
        Praxis Burgweg
        <br />
        Anja Hostettler Streit
        <br />
        Burgweg 1<br />
        3510 Konolfingen
        <br />
        Schweiz
        <br />
        E-Mail: praxisburgweg@hin.ch
        <br />
      </p>
      <h2 className="subtitle">Vertretungsberechtigte Person(en)</h2>
      <p className="paragraph">
        Dr. med. Anja Hostettler Streit, Zeichnungsberechtigte <br />
        Prof. Dr. med. Sven Streit, PhD, Zeichnungsberechtigter
      </p>
      <h2 className="subtitle">Handelsregister-Eintrag</h2>
      <p className="paragraph">
        Eingetragener Firmenname: Praxis Burgweg <br />
        Handelsregister Nr: CHE-199.972.591
      </p>
      <h2 className="subtitle">Haftungsausschluss</h2>
      <p className="paragraph">
        Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen
        Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und
        Vollständigkeit der Informationen. Haftungsansprüche gegen den Autor
        wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff
        oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen,
        durch Missbrauch der Verbindung oder durch technische Störungen
        entstanden sind, werden ausgeschlossen. Alle Angebote sind
        unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der
        Seiten oder das gesamte Angebot ohne besondere Ankündigung zu verändern,
        zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder
        endgültig einzustellen.
      </p>
      <h2 className="subtitle">Haftungsausschluss für Links</h2>
      <p className="paragraph">
        Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres
        Verantwortungsbereichs. Es wird jegliche Verantwortung für solche
        Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten
        erfolgen auf eigene Gefahr des jeweiligen Nutzers.
      </p>
      <h2 className="subtitle">Urheberrechte</h2>
      <p className="paragraph">
        Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder
        anderen Dateien auf dieser Website, gehören ausschliesslich Anja
        Hostettler Streit oder den speziell genannten Rechteinhabern. Für die
        Reproduktion jeglicher Elemente ist die schriftliche Zustimmung des
        Urheberrechtsträgers im Voraus einzuholen.
      </p>
    </div>
  </section>
)
