import React from "react"

import { IconContext } from "react-icons"

import { Navbar } from "../components/navbar"
import "../style/styles.scss"
import { ImpressumSection } from "../components/ImpressumSection"
import { DataprivacySection } from "../components/DataprivacySection"

const PraxisBurgweg = () => (
  <IconContext.Provider value={{ className: "pb-icon" }}>
    <Navbar />
    <ImpressumSection />
    <DataprivacySection />

  </IconContext.Provider>
)

export default PraxisBurgweg
